import React, { useEffect, useState } from 'react';
import { useTranslate } from 'react-admin';
import { Box, Container, Typography, Stack } from '@mui/material';
import { Helmet } from 'react-helmet';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import { getRegisterFormData } from '../common_modules/components/Common';
import RegisterLayout from '../common_modules/components/RegisterLayout';
import RegisterHeaderProgress from '../common_modules/components/RegisterHeaderProgress';

interface RegisterFormData {
  email?: string;
  mobilePhone?: string;
}

const FinishRegistration: React.FC = () => {
  const translate = useTranslate();

  const [tel, setTel] = useState<string | undefined>('');
  const [email, setEmail] = useState<string | undefined>('');

  useEffect(() => {
    const formData = getRegisterFormData() as RegisterFormData;
    if (formData) {
      setEmail(formData.email);
      setTel(formData.mobilePhone);
    }
  }, []);

  return (
    <RegisterLayout>
      <Container
        sx={{ 
          maxWidth: { sm: '552px', md: '772px' },
          marginTop: { xs: '20px', sm: '40px'},
          marginBottom: { xs: '20px', sm: '40px'},
          borderRadius: { sm: '24px', md: '24px' }, 
          border: { sm: '1px solid #EEEEEE', md: '1px solid #EEEEEE' },
          backgroundColor: '#FFFFFF'
        }}
      >
        <Helmet>
          <title>{translate('resources.registers.name.Identification')}</title>
        </Helmet>
        <Stack 
          spacing={1}
          sx={{ 
            paddingLeft: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingRight: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingTop: { sm: '40px', md: '64px' },
            paddingBottom: { sm: '40px', md: '64px' }
          }}
        >
          <RegisterHeaderProgress
            title={translate('resources.registers.name.Identification')}
            icon={<HowToRegOutlinedIcon />}
            step={7}
            totalSteps={7}
          />

          <Box display="flex" justifyContent="center">
            <Box 
              sx={{
                width: { xs: '288px', sm: '400px' },
                height: '20px',
              }}
              component="img" 
              src={`/images/stepper/img_stepper_6.svg`} 
              alt="stepper_6" 
            />
          </Box>

          <Typography
            sx={{
              marginTop: '24px'
            }}
          >
            {/* ECサイト登録の申請ありがとうございます。 */}
            ご登録のメールアドレス({email})へ本人確認のメールが送信されましたので、ご確認をお願いいたします。
            <br />
            <span>
              {/* ご登録のメールアドレス({email})へ本人確認のメールが送信されましたので、ご確認をお願いいたします。 */}
              本人確認が完了したら、ECサイト登録のための審査が開始されます。
            </span>
          </Typography>
        </Stack>
      </Container>
    </RegisterLayout>
  );
};

export default FinishRegistration;
