import React, { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Container,
  Box,
  Paper,
  Stack,
  useMediaQuery
} from '@mui/material';
import { contractText } from './ClientAgreementContent';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import RegisterHeaderProgress from '../common_modules/components/RegisterHeaderProgress';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import RegisterLayout from '../common_modules/components/RegisterLayout';
import RegisterSubmitButton from '../common_modules/components/RegisterSubmitButton';
import WrappedCheckBoxFormControlLabel from '../common_modules/components/WrappedCheckBoxFormControlLabel';
import LinkButton from '../common_modules/components/LinkButton';

const Contract: React.FC = () => {
  const [isAgreed, setIsAgreed] = React.useState(false);
  const navigate = useNavigate();
  const translate = useTranslate();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('sm'));

  useEffect(() => {
    const registerToken = sessionStorage.getItem('register_token'); // セッションストレージからトークンを取得
    if (!registerToken) {
      // トークンが存在しない場合は token-error ページにリダイレクト
      navigate('/register/client/token-error', { replace: true });
    }
  }, [navigate]);

  const handleAgreeChange = () => {
    setIsAgreed(!isAgreed);
  };

  const handleNext = () => {
    navigate('/register/client/form');
  };

  const gap = isSmallScreen ? 3 : 5;
  return (
    <RegisterLayout>
      <Container
        sx={{ 
          maxWidth: { sm: '552px', md: '772px' },
          marginTop: { xs: '20px', sm: '40px'},
          marginBottom: { xs: '20px', sm: '40px'},
          borderRadius: { sm: '24px', md: '24px' }, 
          border: { sm: '1px solid #EEEEEE', md: '1px solid #EEEEEE' },
          backgroundColor: '#FFFFFF'
        }}
      >
        <Stack 
          direction={'column'} 
          spacing={gap}
          sx={{ 
            paddingLeft: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingRight: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingTop: { sm: '40px', md: '64px' },
            paddingBottom: { sm: '40px', md: '64px' }
          }}
        >
          <Helmet>
            <title>{translate('resources.registers.name.Contract')}</title>
          </Helmet>
          <RegisterHeaderProgress
            title={translate('resources.registers.name.Contract')}
            icon={<DescriptionOutlinedIcon />}
            step={2}
            totalSteps={7}
          />

          <Box display="flex" justifyContent="center">
            <Box 
              sx={{
                width: { xs: '288px', sm: '400px' },
                height: '20px',
              }}
              component="img" 
              src={`/images/stepper/img_stepper_2.svg`} 
              alt="stepper_2" 
            />
          </Box>

          <Paper elevation={0} style={{ backgroundColor: '#F5F5F5', padding: 24, height: 540, overflow: 'auto' }}>
            <Typography 
              sx={{
                fontSize: '14px',
                lineHeight: '28px',
                letterSpacing: '0.4px',
                fontWeight: 300
              }}
              paragraph dangerouslySetInnerHTML={{ __html: contractText }} 
            />
          </Paper>

          <WrappedCheckBoxFormControlLabel
            control={<Checkbox checked={isAgreed} onChange={handleAgreeChange} />}
            label="契約内容に同意する"
          />

          <Stack alignItems="center" spacing={3}>
            <RegisterSubmitButton onClick={handleNext} disabled={!isAgreed}>
              {/* 次へ */}
              基本情報入力へ
            </RegisterSubmitButton>
            {/* <LinkButton href="/register/client/terms">{'< 利用規約に戻る'}</LinkButton> */}
            <LinkButton href="/register/client/terms">{'< 利用規約に戻る'}</LinkButton>
          </Stack>
        </Stack>
      </Container>
    </RegisterLayout>
  );
};

export default Contract;
