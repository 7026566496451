import React, { useEffect, useState } from 'react';
import { Box, Button, Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  SimpleForm,
  TextInput,
  required,
  useTranslate,
  ImageInput,
  ImageField,
  useNotify,
} from 'react-admin';
import { CardContent } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import NumericRadioButtonGroupInput from '../common_modules/components/NumericRadioButtonGroupInput';
import RegisterHeaderProgress from '../common_modules/components/RegisterHeaderProgress';
import RegisterSubmitButton from '../common_modules/components/RegisterSubmitButton';
import RegisterFormLayout from '../common_modules/components/RegisterFormLayout';
import { EC_SITE_TYPE } from '../common_modules/constants/choices';
import { getRegisterFormData, getRegisterToken, setRegisterFormData } from '../common_modules/components/Common';
import dataProvider from '../dataProvider';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import LinkButton from '../common_modules/components/LinkButton';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import { useFormContext } from 'react-hook-form';
import Resizer from 'react-image-file-resizer';
import RequiredLabel from '../common_modules/components/RequiredLabel';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';

const CustomDivider = styled(Divider)(({ theme }) => ({
  borderColor: '#000000'
}));

const AddressButton = styled(Button)(({ theme }) => ({
  width: '118px',
  height: '48px',
  borderRadius: '4px',
  marginTop: '7px',
  padding: '0px'
}));

interface RegisterFormData {
  [key: string]: any;
}

// 画像をリサイズ、base64に変換してsessionStorageに保存する関数
const saveResizedImageToSessionStorage = (
  file: File | null,
  key: string,
  maxWidth: number,
  maxHeight: number,
  format: string,
  quality: number = 100
) => {
  if (file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const widthRatio = maxWidth / img.width;
        const heightRatio = maxHeight / img.height;
        const scaleRatio = Math.min(widthRatio, heightRatio, 1); // 縮小倍率を計算（1以下に制限）

        if (scaleRatio === 1) {
          // 画像の幅と高さが両方 max 以下なので、リサイズ不要
          const base64data = event.target?.result as string;
          sessionStorage.setItem(key, base64data);
          console.log(`Original image dimensions retained: ${img.width} x ${img.height}`);
        } else {
          // リサイズが必要な場合のみリサイズ処理を実行
          const resizeWidth = img.width * scaleRatio;
          const resizeHeight = img.height * scaleRatio;

          Resizer.imageFileResizer(
            file,
            resizeWidth,
            resizeHeight,
            format,
            quality,
            0,
            (resizedImage) => {
              const base64data = resizedImage as string;
              sessionStorage.setItem(key, base64data);
              console.log(`Resized image dimensions: ${resizeWidth} x ${resizeHeight}`);
            },
            'base64'
          );
        }
      };
      img.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  } else {
    sessionStorage.removeItem(key);
  }
};

const CustomToolbar = ({ loading, isRedirectFromConfirm }: { loading: boolean, isRedirectFromConfirm: boolean }) => (
  <Stack alignItems="center" spacing={3} mt={1}>
    <RegisterSubmitButton type="submit" disabled={loading}>
      {/* 登録内容の確認へ */}
      {isRedirectFromConfirm ? '情報を更新する' : '登録内容の確認へ'}
    </RegisterSubmitButton>
    {/* <LinkButton href="/register/client/form">{'< 基本情報入力に戻る'}</LinkButton> */}
    <LinkButton href={isRedirectFromConfirm ? "/register/client/confirm" : "/register/client/form"}>
      {isRedirectFromConfirm ? '< 変更をキャンセルして戻る' : '< 基本情報入力に戻る'}
    </LinkButton>
  </Stack>
);
const CustomCardContent = styled(CardContent)(({ theme }) => ({
  paddingBottom: '0px'
}));
const FormContents: React.FC<{ defaultValues: RegisterFormData }> = ({ defaultValues }) => {
  const translate = useTranslate();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const { getValues, setValue } = useFormContext();
  const notify = useNotify();
  const theme = useTheme();

  const [placeholderText, setPlaceholderText] = useState(
    "アップロードする画像をドロップ、または選択してください"
  );

  useEffect(() => {
    const updatePlaceholder = () => {
      if (window.innerWidth < 600) {
        setPlaceholderText("アップロードする画像をドロップ、<br />または選択してください");
      } else {
        setPlaceholderText("アップロードする画像をドロップ、または選択してください");
      }
    };

    window.addEventListener("resize", updatePlaceholder);
    updatePlaceholder();

    return () => window.removeEventListener("resize", updatePlaceholder);
  }, []);

  const handleGetAddress = async () => {
    try {
      const { zipCode } = getValues();
      const url = `/registers/partners/address/${zipCode}`;
      const response = await dataProvider.register({}, url, 'GET');
      const data = response.data;

      setValue('prefecture', data.prefecture);
      setValue('city', data.city);
      setValue('address1', data.address1);

      console.log('address=' + zipCode);
    } catch (e) {
      notify('住所の取得に失敗しました。', { type: 'error' });
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <RequiredLabel label={translate('resources.registers.fields.name')} />
      <TextInput 
        source="name" 
        label={false}
        placeholder={translate('resources.registers.fields.name')} 
        validate={required()} 
      />
      
      <RequiredLabel label={translate('resources.registers.fields.ecSiteType')} />
      <NumericRadioButtonGroupInput
        source="ecSiteType"
        label={translate('resources.registers.fields.ecSiteType')}
        validate={required()}
        choices={EC_SITE_TYPE}
        isLabelRequired={false}
      />

      <RequiredLabel label={translate('resources.registers.fields.ecSiteUrl')} />
      <TextInput 
        source="ecSiteUrl" 
        label={false}
        placeholder={translate('resources.registers.fields.ecSiteUrl')} 
        validate={required()} 
      />
      
      <RequiredLabel label={'ストアロゴ画像'} />
      <ImageInput
        placeholder={<p dangerouslySetInnerHTML={{ __html: placeholderText }} />}
        source="storeLogoThumbnail"
        label="ストアロゴ画像"
        accept="image/png"
        defaultValue={defaultValues.storeLogoThumbnail}
        helperText={
          <span
            style={{
              display: "block",
              wordBreak: "break-word",
              overflowWrap: "break-word",
              whiteSpace: "normal",
              maxWidth: "100%",
            }}
            dangerouslySetInnerHTML={{
              __html: 'ストアロゴ画像はPNG形式でアップロードしてください。(背景透過推奨) 幅360px、高さ300px以下にリサイズされます。'
            }}
          />
        }
        onChange={(e) => {
          const file = e.target?.files ? e.target.files[0] : e;
          const format = file.type === 'image/png' ? 'PNG' : 'JPEG';
          saveResizedImageToSessionStorage(file, 'storeLogoThumbnail', 360, 300, format, 100);
        }}
        sx={{
          marginTop: '7px',
          marginBottom: '14px',
          '& .RaFileInput-dropZone': {
            border: `2px dotted rgba(0, 0, 0, 0.23)`
          },
          '& .RaImageInput-helperText': {
            whiteSpace: 'normal',
          },
          '& .css-1v6xbkz-MuiTypography-root': {
            display: 'none'
          },
          '& .css-co9xzg-MuiFormHelperText-root': {
            opacity: 0.5,
            backgroundColor: '#EEEEEE',
            padding: '16px',
            color: '#000000',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '150%',
            letterSpacing: '0%',
            marginBottom: '10px'
          }
        }}
      >
        <ImageField source="src" title="ストア画像" />
      </ImageInput>
      
      <RequiredLabel label={'バナー画像'} isRequired={false} />
      <ImageInput
        placeholder={<p dangerouslySetInnerHTML={{ __html: placeholderText }} />}
        source="bannerImage"
        label="バナー画像"
        accept="image/png,image/jpeg"
        defaultValue={defaultValues.bannerImage}
        helperText={
          <span
            style={{
              display: "block",
              wordBreak: "break-word",
              overflowWrap: "break-word",
              whiteSpace: "normal",
              maxWidth: "100%",
            }}
            dangerouslySetInnerHTML={{
              __html:
                'バナー画像はJPEG/PNG形式でアップロードしてください。幅500px、高さ500px以下にリサイズされます。'
            }}
          />
        }
        onChange={(e) => {
          const file = e.target?.files ? e.target.files[0] : e;
          const format = file.type === 'image/png' ? 'PNG' : 'JPEG';
          saveResizedImageToSessionStorage(file, 'bannerImage', 500, 500, format, 100);
        }}
        sx={{
          marginTop: '7px',
          marginBottom: '24px',
          '& .RaFileInput-dropZone': {
            border: `2px dotted rgba(0, 0, 0, 0.23)`
          },
          '& .RaImageInput-helperText': {
            whiteSpace: 'normal'
          },
          '& .css-1v6xbkz-MuiTypography-root': {
            display: 'none'
          },
          '& .css-co9xzg-MuiFormHelperText-root': {
            opacity: 0.5,
            backgroundColor: '#EEEEEE',
            padding: '16px',
            color: '#000000',
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '150%',
            letterSpacing: '0%'
          }
        }}
      >
        <ImageField source="src" title="バナー画像" />
      </ImageInput>
    </Box>
  );
};

const FormStore: React.FC = () => {
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const translate = useTranslate();
  const [defaultValues, setDefaultValues] = useState<RegisterFormData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isRedirectFromConfirm = searchParams.get('redirect-from-confirm') === 'true';

  useEffect(() => {
    const registerToken = sessionStorage.getItem('register_token'); // セッションストレージからトークンを取得
    if (!registerToken) {
      // トークンが存在しない場合は token-error ページにリダイレクト
      navigate('/register/client/token-error', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    // formDataとsessionStorageから画像データを取得してdefaultValuesに設定
    const formData = getRegisterFormData() as RegisterFormData;
    const storeLogoThumbnail = sessionStorage.getItem('storeLogoThumbnail');
    const bannerImage = sessionStorage.getItem('bannerImage');

    setDefaultValues({
      ...formData,
      ecSiteType: 1,
      storeLogoThumbnail: storeLogoThumbnail ? { src: storeLogoThumbnail } : undefined,
      bannerImage: bannerImage ? { src: bannerImage } : undefined
    });
  }, [navigate]);

  const handleSubmit = async (data: RegisterFormData) => {
    setIsLoading(true);
    try {
      const token = getRegisterToken();
      if (!token) {
        navigate('/register/client/token-error');
        return;
      }

      if (!data.address2) {
        data.address2 = '';
      }

      // sessionStorageから画像データを取得して送信データに含める
      const storeLogoThumbnail = sessionStorage.getItem('storeLogoThumbnail');
      const bannerImage = sessionStorage.getItem('bannerImage');

      const sendingFormData = {
        token,
        ...data,
        storeLogoThumbnail,
        bannerImage,
        closingDate: 99,
        invoiceDate: 99,
        plannedPaymentMonth: 1,
        plannedPaymentDate: 99
      };

      const registerClientStoreUrl = `/registers/clients/create-test-store`;
      await dataProvider.register(sendingFormData, registerClientStoreUrl);

      setRegisterFormData(sendingFormData); // sessionStorageに保存
      navigate(`/register/client/confirm`); // 確認画面へ遷移
    } catch (error) {
      handleError();
      return (error as any).body.errors;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RegisterFormLayout>
      <Container
        sx={{ 
          maxWidth: { sm: '552px', md: '772px' },
          marginTop: { xs: '20px', sm: '40px'},
          marginBottom: { xs: '20px', sm: '40px'},
          borderRadius: { sm: '24px', md: '24px' }, 
          border: { sm: '1px solid #EEEEEE', md: '1px solid #EEEEEE' },
          backgroundColor: '#FFFFFF'
        }}
      >
        <Helmet>
          <title>{translate('resources.registers.name.FormStoreInformation')}</title>
        </Helmet>
        <Stack 
          spacing={1}
          sx={{ 
            paddingLeft: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingRight: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingTop: { sm: '40px', md: '64px' },
            paddingBottom: { sm: '40px', md: '64px' }
          }}
        >
          <RegisterHeaderProgress
            title={translate('resources.registers.name.FormStoreInformation')}
            icon={<StoreOutlinedIcon />}
            step={3}
            totalSteps={7}
          />

          <Box display="flex" justifyContent="center">
            <Box 
              sx={{
                width: { xs: '288px', sm: '400px' },
                height: '20px',
              }}
              component="img" 
              src={`/images/stepper/img_stepper_4.svg`} 
              alt="stepper_4" 
            />
          </Box>

          <Typography 
            variant="body1"
            sx={{
              marginBottom: '14px'
            }}
          >
            運営しているECサイトの情報を入力してください。
          </Typography>

          <Box display="flex" justifyContent="center">
            <Box
              sx={{
                width: '100%',
              }}
            >
              <SimpleForm
                defaultValues={defaultValues}
                toolbar={<CustomToolbar loading={isLoading} isRedirectFromConfirm={isRedirectFromConfirm} />}
                onSubmit={handleSubmit}
                component={CustomCardContent}
                gap={3}
                sx={{
                  p: 0,
                  '& .MuiTextField-root': { width: '100%' }
                }}
              >
                {defaultValues && <FormContents defaultValues={defaultValues} />}
              </SimpleForm>
            </Box>
          </Box>
        </Stack>
      </Container>
    </RegisterFormLayout>
  );
};

export default FormStore;
