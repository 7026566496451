import messages from '@bicstone/ra-language-japanese';

export const japaneseMessages = {
  ...messages,
  Role: '',
  resources: {
    accounts: {
      name: 'スタッフ管理',
      fields: {
        id: '管理者ID',
        name: '名前',
        email: 'メールアドレス',
        authority: '権限',
        accountInvalidFlag: 'アカウント有効フラグ',
        lastLoginDate: '最終利用日時'
      }
    },
    invitations: {
      name: 'パートナー招待',
      fields: {
        id: 'ID',
        inviteName: '被招待者',
        inviteEmail: 'メールアドレス',
        inviteStatus: 'ステータス',
        name: '被招待者名',
        email: 'メールアドレス',
        partnerReward: '報酬割合',
        introducerReward: '紹介報酬',
        parentPartner: '親パートナー'
      }
    },
    partners: {
      name: 'パートナー管理',
      fields: {
        id: 'アフィリエイトID',
        partnerId: 'パートナーID',
        postUrl: '使用WEBサイトURL',
        applyStatus: '承認状況',
        expirationDate: '有効期限',
        partnerReward: 'パートナー報酬',
        introducerReward: '紹介報酬',
        invalidFlag: 'ステータス',
        memo: '備考',
        partnerType: 'アカウント種別',
        name: 'パートナー登録名',
        lastName: '姓',
        firstName: '名',
        email: 'パートナーメールアドレス',
        tel: 'パートナー電話番号',
        address: '住所',
        zipCode: '郵便番号',
        prefecture: '都道府県',
        city: '市区町村',
        address1: '番地',
        address2: 'ビル名 部屋番号',
        invoiceNumber: 'インボイス登録番号',
        frontImage: '身分証明書 表面',
        backImage: '身分証明書 裏面',
        dunsNumber: 'D-U-N-S番号',
        website: 'Webサイト'
      }
    },
    orders: {
      name: '注文管理',
      fields: {
        clientId: 'ECサイト',
        orderId: 'ECサイト注文ID',
        ecSiteClientId: 'ECサイト顧客ID',
        affiliateId: 'アフィリエイトID',
        orderDate: '注文日時',
        price: '価格(税抜)',
        isCancelled: 'キャンセル'
      }
    },
    analytics: {
      name: {
        analytics: 'アナリティクス',
        all: '全体',
        clients: 'ECサイト別',
        partners: 'パートナー別'
      },
      fields: {
        totalSalesAmount: 'Successfee経由総売上',
        totalFeebackCommission: 'Successfee手数料',
        totalIntroducerCommission: '紹介報酬額',
        totalAffiliateCommission: 'パートナー報酬支払額',
        totalSalesCount: '合計売上件数',
        totalClickCount: '合計訪問数',
        startDate: '開始日',
        endDate: '終了日',
        selectClient: 'ECサイト選択',
        selectPartner: 'パートナー選択',
        granularity: '粒度',
        graphTarget: 'グラフ対象選択',
        resultType: '内訳対象選択',
        date: '期間',
        details: '売上と件数の内訳',
        downloadCSV: 'CSVダウンロード',
        salesAmount: '売上高',
        salesAmountRate: '売上割合',
        adjustedSalesAmount: '売上高',
        feebackCommission: 'Successfee手数料',
        partnerCommission: 'パートナー報酬支払額',
        introducerCommission: '紹介報酬額',
        affiliateCommission: 'パートナー報酬支払額',
        salesCount: '売上件数',
        clickCount: '訪問数'
      }
    },
    invoices: {
      name: '請求管理',
      fields: {
        issueDate: '発行日',
        invoiceNumber: '請求番号',
        salesStartDate: '売上開始日時',
        salesEndDate: '売上終了日時',
        subTotal: '小計',
        salesTax: '消費税',
        totalAmount: '合計金額（税込）',
        status: '支払ステータス',
        paymentDeadline: '振込期日',
        invoiceMemo: '備考',
        invoiceLink: '請求書リンク',
        invoicePdf: '請求書',
        receiptPdf: '領収書',
        invoiceDetailPdf: '明細をダウンロード'
      }
    },
    settings: {
      name: '設定',
      fields: {
        name: '登録名',
        email: 'メールアドレス',
        tel: '電話番号',
        zipCode: '郵便番号',
        address: '住所',
        prefecture: '都道府県',
        city: '市区町村',
        address1: '番地',
        address2: 'ビル名 部屋番号',
        bankNumber: '銀行番号',
        bankName: '銀行名',
        bankBranchNumber: '支店番号',
        bankBranchName: '支店名',
        bankAccountType: '口座種別',
        bankAccountNumber: '口座番号',
        bankAccountName: '受取人名',
        ecSiteUrl: 'ECサイトURL',
        ecSiteType: 'ECサイトタイプ',
        closingDate: '締め日',
        invoiceDate: '請求日',
        plannedPaymentMonth: '支払い予定月',
        plannedPaymentDate: '支払い予定日',
        defaultPartnerReward: 'パートナー報酬(デフォルト)',
        inviteCodeUrl: 'パートナー招待URL'
      }
    },
    registers: {
      name: {
        Terms: '利用規約',
        Contract: '契約内容',
        Form: '必要事項入力',
        FormBasicInput: '基本情報入力',
        FormStoreInformation: 'ストア情報入力',
        Confirmation: '入力内容確認',
        ConfirmRegistrationDetails: '登録内容の確認',
        Verification: '審査',
        ReviewApplication: '審査申請完了',
        Completion: '登録申請完了',
        Identification: '本人確認',
        Conditions: '利用規約',
        CreateStaff: 'パスワード登録',
        Upload: '身分証明書提出',
        SMSAuth: 'SMS認証',
        Error: 'エラー'
      },
      fields: {
        partnerType: 'アカウント種別',
        name: 'ECサイト名',
        lastName: '姓',
        firstName: '名',
        zipCode: '郵便番号',
        address: '住所',
        prefecture: '都道府県',
        city: '市区町村',
        address1: '番地',
        address2: 'ビル名 部屋番号',
        address2Updated: '建物名、部屋番号など',
        tel: '電話番号',
        email: 'メールアドレス',
        emailConfirm: 'メールアドレス(確認)',
        bankNumber: '銀行番号',
        bankName: '銀行名',
        bankBranchNumber: '支店番号',
        bankBranchName: '支店名',
        bankAccountType: '口座種類',
        bankAccountNumber: '口座番号',
        bankAccountName: '口座名義',
        invoiceNumber: 'インボイス登録番号',
        dunsNumber: 'D-U-N-S番号',
        website: 'Webサイト',
        ecSiteType: 'ECサイトタイプ',
        ecSiteUrl: 'ECサイトURL',
        storeLogoThumbnail: 'ストア画像',
        bannerImage: 'バナー画像',
        frontImage: '身分証明書 表画像',
        backImage: '身分証明書 裏画像',
        userName: '初期ユーザー名',
        userEmail: '初期ユーザーのメールアドレス',
        userPassword: 'パスワード',
        userPasswordConfirm: 'パスワード確認',
        confirmationCode: '認証番号'
      }
    }
  },
  values: {
    authority: {
      0: 'アカウント保持者',
      1: '管理者',
      2: 'マネージャー',
      3: '経理'
    },
    partnerType: {
      1: '個人',
      2: '法人'
    },
    accountStatus: {
      0: '無効',
      1: '有効',
      2: '仮登録'
    },
    accountInvalidFlag: {
      0: '無効',
      1: '有効',
      2: '仮登録'
    },
    ecSiteType: {
      1: 'Shopify'
      // 99: 'その他'
    },
    bankAccountType: {
      1: '普通',
      2: '当座'
    },
    paymentMonth: {
      0: '当月',
      1: '翌月',
      2: '翌々月'
    },
    paymentDate: '%{day}日',
    paymentDateEndOfMonth: '月末',
    'apply-status': {
      1: '未承認',
      2: '申請済み',
      3: '却下'
    },
    clientManagementType: {
      1: '全て',
      2: '個別管理'
    },
    depositStatus: {
      1: '未支払',
      2: '支払済み'
    },
    inviteStatus: {
      1: '招待済み',
      2: '申請済み',
      3: '有効期限切れ',
      4: '申請中'
    },
    applyStatus: {
      1: '未承認',
      2: '承認済み',
      3: '却下'
    },
    invalidFlag: {
      0: '無効',
      1: '有効'
    },
    isCancelled: {
      true: 'キャンセル',
      false: ''
    },
    paymentStatus: {
      1: '支払い不要',
      2: '未振込',
      3: '振込済み',
      4: 'エラー'
    },
    resultTypes: {
      1: '全体',
      2: 'ECストア',
      3: 'パートナー'
      // 4: 'アフィリエイト'
    },
    graphTargetTypes: {
      salesAmount: 'Successfee経由総売上',
      feebackCommission: 'Successfee手数料',
      partnerCommission: 'パートナー報酬支払額',
      introducerCommission: '紹介報酬額',
      affiliateCommission: 'パートナー報酬支払額',
      salesCount: '売上件数',
      clickCount: '訪問数'
    },
    graphTargetUnits: {
      salesAmount: '円',
      feebackCommission: '円',
      partnerCommission: '円',
      introducerCommission: '円',
      affiliateCommission: '円',
      salesCount: '件',
      clickCount: '回'
    }
  },
  cognito: {
    Unknown: 'サーバーとの通信に失敗しました。しばらくしてから再度お試しください。',
    MeEndpointException: 'ユーザーの取得に失敗しました。現在アカウントが利用できません。',
    NotAuthorizedException: 'ユーザー名もしくはパスワードが間違っています。',
    UserNotFoundException: 'ユーザーが見つかりません。',
    CodeMismatchException: '認証コードが間違っています。',
    UsernameExistsException: 'すでに同じユーザーが登録されています。',
    InvalidPasswordException:
      'パスワードは大文字、小文字、数字、英字をそれぞれ1文字以上含む8文字以上で設定してください。',
    ExpiredCodeException: '認証コードの有効期限が切れています。',
    LimitExceededException: 'リクエストが制限を超えました。しばらく時間をおいてから再度お試しください。'
  }
};
