import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { Helmet } from 'react-helmet';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslate } from 'react-admin';
import RegisterLayoutWithoutHeader from '../common_modules/components/RegisterLayoutWithoutHeader';

const ClientRegisterTokenError: React.FC = () => {
  const translate = useTranslate();
  return (
    <RegisterLayoutWithoutHeader>
      <Container>
        <Helmet>
          <title>{translate('resources.registers.name.Error')}</title>
        </Helmet>
        <Box display="flex" alignItems="center" sx={{ padding: 2 }}>
          <ErrorOutlineIcon sx={{ marginRight: 1 }} />
          <Typography variant="h5" align="left" sx={{ fontFamily: 'Noto Sans JP' }}>
            招待URLが不正です。
          </Typography>
        </Box>
        <Typography variant="body1" align="left" sx={{ padding: 2, fontFamily: 'Noto Sans JP' }}>
          招待URLを確認してください。
          <br />
          招待メールのURLから直接遷移した画面で登録を行ってください。
        </Typography>
      </Container>
    </RegisterLayoutWithoutHeader>
  );
};

export default ClientRegisterTokenError;
