import React from "react";
import { Box, Typography } from "@mui/material";

interface RequiredLabelProps {
  label: string;
  isRequired?: boolean;
}

const RequiredLabel: React.FC<RequiredLabelProps> = ({ label, isRequired = true }) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography variant="body1" sx={{ color: "gray", fontSize: "16px" }}>
        {label}
      </Typography>
      
      {isRequired && (
        <Box
          sx={{
            backgroundColor: "#FF6763",
            color: "white",
            fontSize: "14px",
            fontWeight: 400,
            borderRadius: "4px",
            padding: "4px 4px",
            marginLeft: "8px",
            lineHeight: "16.8px"
          }}
        >
          必須
        </Box>
      )}
    </Box>
  );
};

export default RequiredLabel;
