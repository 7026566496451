import React from 'react';
import styled from '@emotion/styled';
import Footer from './Footer';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import SiteNameAtTopHeader from './SiteNameAtTopHeader';

const LayoutContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh'
});

const ContentContainer = styled(Box)({
  flex: '1 0 auto'
});

const RegisterFormLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { pathname } = useLocation();
  const isSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  const sxMargin = isSmall
    ? {
        mt: 1,
        mb: 1,
        ml: 2,
        mr: 2
      }
    : {
        mt: 2,
        mb: 2,
        ml: 3,
        mr: 3
      };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <LayoutContainer
      sx={{
        backgroundColor: {xs: '#FFFFFF', sm: '#F5F5F5'},
        '& .MuiBox-root': {
          margin: 0
        },
        '& .MuiContainer-root': {
          padding: 0,
        }
      }}
    >
      <SiteNameAtTopHeader />
      <ContentContainer sx={sxMargin}>{children}</ContentContainer>
      <Footer />
    </LayoutContainer>
  );
};

export default RegisterFormLayout;
