import {
  DateField,
  Edit,
  List,
  Pagination,
  NumberField,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextInput,
  useDataProvider,
  useRecordContext,
  useTranslate,
  TopToolbar,
  ExportButton,
  useListContext,
  downloadCSV
} from 'react-admin';
import { Box } from '@mui/material';
import jsonExport from 'jsonexport/dist';
import Button from '@mui/material/Button';
import { PAYMENT_STATUS } from '../common_modules/constants/choices';
import EnumTextField from '../common_modules/components/EnumTextField';
import NumericRadioButtonGroupInput from '../common_modules/components/NumericRadioButtonGroupInput';
import FlexibleListLayout from '../common_modules/components/FlexibleListLayout';

const ListActionsWithExport = ({ exporter }: { exporter: (records: any[]) => void }) => {
  const { total } = useListContext();
  return (
    <TopToolbar>
      <ExportButton label="CSVダウンロード" exporter={exporter} disabled={total === 0} />
    </TopToolbar>
  );
};

const LinkToInvoiceButton = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  if (!record) return null;

  const hasLink = record.invoiceLink !== null;
  return (
    hasLink && (
      <Button
        size="small"
        color="primary"
        variant="contained"
        sx={{ display: 'inline-flex', alignItems: 'center' }}
        onClick={(event) => {
          event.stopPropagation();
          dataProvider.download(record.invoiceLink);
        }}
      >
        {translate('resources.invoices.fields.invoicePdf')}
      </Button>
    )
  );
};

const LinkToReceiptButton = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  if (!record) return null;

  const hasLink = record.receiptLink !== null;
  return (
    hasLink && (
      <Button
        size="small"
        color="primary"
        variant="contained"
        sx={{ display: 'inline-flex', alignItems: 'center' }}
        onClick={(event) => {
          event.stopPropagation();
          dataProvider.download(record.receiptLink);
        }}
      >
        {translate('resources.invoices.fields.receiptPdf')}
      </Button>
    )
  );
};

const LinkToInvoiceDetailButton = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  if (!record) return null;

  const hasLink = record.invoiceLink !== null;
  return (
    hasLink && (
      <Button
        size="small"
        color="primary"
        variant="contained"
        sx={{ display: 'inline-flex', alignItems: 'center' }}
        onClick={(event) => {
          event.stopPropagation();
          dataProvider.download(record.invoiceDetailLink);
        }}
      >
        {translate('resources.invoices.fields.invoiceDetailPdf')}
      </Button>
    )
  );
};

export const InvoiceList = () => {
  const translate = useTranslate();

  const handleExport = (records: any[]) => {
    if (!records || records.length === 0) {
      console.log('No records to export');
      return;
    }
    const exportFields = ['issueDate', 'paymentDeadline', 'status', 'invoiceLink'];
    const keys = exportFields;
    const headers = keys.map((key) => translate(`resources.invoices.fields.${key}`, { _: key }));

    // データ変換
    const dataToExport = records.map((record) => {
      const transformedRecord: Record<string, any> = {};
      keys.forEach((key) => {
        let value = record[key];
        // status フィールドの変換
        if (key === 'status') {
          value = translate(`values.depositStatus.${value}`, { _: value });
        }
        transformedRecord[translate(`resources.invoices.fields.${key}`, { _: key })] = value || '';
      });
      return transformedRecord;
    });

    // CSVエクスポート処理
    jsonExport(dataToExport, { headers }, (err: Error | null, csv: string) => {
      if (err) {
        console.error(err);
        return;
      }
      const bom = '\uFEFF';
      const csvWithBom = bom + csv;
      downloadCSV(csvWithBom, '請求');
    });
  };

  return (
    <List
      actions={<ListActionsWithExport exporter={handleExport} />}
      exporter={handleExport}
      pagination={<Pagination />}
    >
      <FlexibleListLayout rowClick="show" bulkActionButtons={false}>
        <DateField source="issueDate" label={translate('resources.invoices.fields.issueDate')} />
        <DateField source="paymentDeadline" label={translate('resources.invoices.fields.paymentDeadline')} />
        <EnumTextField
          source="status"
          translationKeyPrefix="values.depositStatus"
          label={translate('resources.invoices.fields.status')}
        />
        <LinkToInvoiceButton />
        <LinkToReceiptButton />
        <LinkToInvoiceDetailButton />
      </FlexibleListLayout>
    </List>
  );
};

export const InvoiceShow = () => (
  <Show>
    <SimpleShowLayout>
      <DateField source="issueDate" />
      <DateField source="salesStartDate" />
      <DateField source="salesEndDate" />
      <NumberField source="subTotal" />
      <NumberField source="salesTax" />
      <NumberField source="totalAmount" />
      <DateField source="paymentDeadline" />
      <EnumTextField source="status" translationKeyPrefix="values.depositStatus" />
      {/* <LinkToInvoiceButton />
      <LinkToReceiptButton />
      <LinkToInvoiceDetailButton /> */}
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
        <LinkToInvoiceButton />
        <LinkToReceiptButton />
        <LinkToInvoiceDetailButton />
      </Box>
    </SimpleShowLayout>
  </Show>
);

export const InvoiceEdit = () => (
  <Edit>
    <SimpleForm sx={{ maxWidth: '600px' }}>
      <TextInput source="name" label="名前" fullWidth />
      <TextInput source="email" label="メール" fullWidth />
      <NumericRadioButtonGroupInput source="status" label="ステータス" choices={PAYMENT_STATUS} />
    </SimpleForm>
  </Edit>
);
