import React, { useEffect, useState } from 'react';
import { Box, Button, Link, ButtonProps, Container, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  SimpleForm,
  TextInput,
  required,
  useTranslate,
  SelectInput,
  ImageInput,
  ImageField,
  useNotify,
} from 'react-admin';
import { CardContent } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import NumericSelectRadioButtonGroupInput from '../common_modules/components/NumericSelectRadioButtonGroupInput';
import NumericRadioButtonGroupInput from '../common_modules/components/NumericRadioButtonGroupInput';
import RegisterHeaderProgress from '../common_modules/components/RegisterHeaderProgress';
import RegisterSubmitButton from '../common_modules/components/RegisterSubmitButton';
import RegisterFormLayout from '../common_modules/components/RegisterFormLayout';
import { BANK_ACCOUNT_TYPE, PREFECTURE_LIST, EC_SITE_TYPE } from '../common_modules/constants/choices';
import { getRegisterFormData, getRegisterToken, setRegisterFormData } from '../common_modules/components/Common';
import dataProvider from '../dataProvider';
import useErrorHandler from '../common_modules/hooks/useErrorHandler';
import LinkButton from '../common_modules/components/LinkButton';
import styled from '@emotion/styled';
import Divider from '@mui/material/Divider';
import { useFormContext } from 'react-hook-form';
import Resizer from 'react-image-file-resizer';
import RequiredLabel from '../common_modules/components/RequiredLabel';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';

const CustomDivider = styled(Divider)(({ theme }) => ({
  borderColor: '#000000'
}));

const AddressButton = styled(Button)(({ theme }) => ({
  width: '118px',
  height: '48px',
  borderRadius: '4px',
  marginTop: '7px',
  padding: '0px'
}));

interface RegisterFormData {
  [key: string]: any;
}

// 画像をリサイズ、base64に変換してsessionStorageに保存する関数
const saveResizedImageToSessionStorage = (
  file: File | null,
  key: string,
  maxWidth: number,
  maxHeight: number,
  format: string,
  quality: number = 100
) => {
  if (file) {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const widthRatio = maxWidth / img.width;
        const heightRatio = maxHeight / img.height;
        const scaleRatio = Math.min(widthRatio, heightRatio, 1); // 縮小倍率を計算（1以下に制限）

        if (scaleRatio === 1) {
          // 画像の幅と高さが両方 max 以下なので、リサイズ不要
          const base64data = event.target?.result as string;
          sessionStorage.setItem(key, base64data);
          console.log(`Original image dimensions retained: ${img.width} x ${img.height}`);
        } else {
          // リサイズが必要な場合のみリサイズ処理を実行
          const resizeWidth = img.width * scaleRatio;
          const resizeHeight = img.height * scaleRatio;

          Resizer.imageFileResizer(
            file,
            resizeWidth,
            resizeHeight,
            format,
            quality,
            0,
            (resizedImage) => {
              const base64data = resizedImage as string;
              sessionStorage.setItem(key, base64data);
              console.log(`Resized image dimensions: ${resizeWidth} x ${resizeHeight}`);
            },
            'base64'
          );
        }
      };
      img.src = event.target?.result as string;
    };
    reader.readAsDataURL(file);
  } else {
    sessionStorage.removeItem(key);
  }
};

const CustomToolbar = ({ loading, isRedirectFromConfirm }: { loading: boolean, isRedirectFromConfirm: boolean }) => (
  <Stack alignItems="center" spacing={3} mt={1}>
    <RegisterSubmitButton type="submit" disabled={loading}>
      {/* 次へ */}
      {isRedirectFromConfirm ? '情報を更新する' : 'ストア情報入力へ'}
    </RegisterSubmitButton>
    {/* <LinkButton href="/register/client/contract">{'< ECサイト契約確認に戻る'}</LinkButton> */}
    <LinkButton href={isRedirectFromConfirm ? "/register/client/confirm" : "/register/client/contract"}>
      {isRedirectFromConfirm ? '< 変更をキャンセルして戻る' : '< 契約内容確認に戻る'}
    </LinkButton>
  </Stack>
);
const CustomCardContent = styled(CardContent)(({ theme }) => ({
  paddingBottom: '0px'
}));
const FormContents: React.FC<{ defaultValues: RegisterFormData }> = ({ defaultValues }) => {
  const translate = useTranslate();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down('md'));
  const { getValues, setValue } = useFormContext();
  const notify = useNotify();
  const theme = useTheme();

  const handleGetAddress = async () => {
    try {
      const { zipCode } = getValues();
      const url = `/registers/partners/address/${zipCode}`;
      const response = await dataProvider.register({}, url, 'GET');
      const data = response.data;

      setValue('prefecture', data.prefecture);
      setValue('city', data.city);
      setValue('address1', data.address1);

      console.log('address=' + zipCode);
    } catch (e) {
      notify('住所の取得に失敗しました。', { type: 'error' });
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      {/* <TextInput source="name" label={translate('resources.registers.fields.name')} validate={required()} /> */}
      <RequiredLabel label={translate('resources.registers.fields.zipCode')} />
      <Stack 
        direction="row" 
        spacing={isSmallScreen ? 2 : 3}
      >
        <Box 
          sx={{ 
            width: { xs: '200px', sm: '264px' } 
          }}>
          <TextInput
            source="zipCode"
            label={false}
            placeholder={translate('resources.registers.fields.zipCode')}
            validate={required()}
            // helperText={'ハイフンなし'}
          />
        </Box>
        <AddressButton 
          variant="contained" 
          color="primary" 
          onClick={handleGetAddress}
          sx={{ 
            width: { xs: '102px', sm: '112px' }
          }}
        >
          住所を入力
        </AddressButton>
      </Stack>

      <RequiredLabel label={translate('resources.registers.fields.prefecture')} />
      <Box
        sx={{
          width: { xs: '200px', sm: '264px' }
        }}
      > 
        <SelectInput
          source="prefecture"
          validate={required()}
          choices={PREFECTURE_LIST}
          label={false}
          // placeholder={translate('resources.registers.fields.prefecture')}
          InputProps={{
            notched: false
          }}
        />
      </Box>
      
      <RequiredLabel label={translate('resources.registers.fields.city')} />
      <TextInput 
        source="city" 
        label={false}
        placeholder={translate('resources.registers.fields.city')} 
        validate={required()} 
      />

      <RequiredLabel label={translate('resources.registers.fields.address1')} />
      <TextInput 
        source="address1" 
        label={false}
        placeholder={translate('resources.registers.fields.address1')} 
        validate={required()} 
      />
      
      <RequiredLabel label={translate('resources.registers.fields.address2Updated')} isRequired={false} />
      <TextInput 
        source="address2"
        label={false} 
        placeholder={translate('resources.registers.fields.address2Updated')} 
      />
      
      <Box
        sx={{ 
          width: { xs: '200px', sm: '264px' }
        }}
      > 
        <RequiredLabel label={translate('resources.registers.fields.tel')} />
        <TextInput
          source="tel"
          label={false}
          placeholder={translate('resources.registers.fields.tel')}
          validate={required()}
          helperText={'ハイフンなし'}
        />
      </Box>
      
      <Box
        sx={{ 
          marginTop: '14px'
        }}
      >
        <RequiredLabel label={translate('resources.registers.fields.email')} />
        <TextInput 
          source="email" 
          label={false}
          placeholder={translate('resources.registers.fields.email')} 
          validate={required()} 
        />
      </Box>

      <Box 
        sx={{
          opacity: 0.5,
          backgroundColor: '#EEEEEE',
          marginBottom: '24px',
        }}
      >
        <Typography 
          align="left"
          sx={{
            padding: '16px',
            color: '#000000',
            fontSize: '12px !important',
            fontWeight: 400,
            letterSpacing: '0%',
            lineHeight: '150%'
          }}
        >
          アカウント登録の際、キャリアメール（@docomo.ne.jp、@ezweb.ne.jp、@softbank.ne.jp など）をご利用いただくと、本人確認などの重要なメールが届かない、または受信制限によりブロックされる場合があります。
          <br />
          GmailやYahoo!メールなどのフリーメールのご利用を推奨いたします。
          {/* <br />
          キャリアメールを使用する場合は、[@successfee.com] からのメールを受信できるよう設定をご確認ください。 */}
        </Typography>
      </Box>
      
      <RequiredLabel label={translate('resources.registers.fields.emailConfirm')} />
      <TextInput
        source="emailConfirm"
        label={false}
        placeholder={translate('resources.registers.fields.emailConfirm')}
        validate={required()}
      />

      {/* <NumericRadioButtonGroupInput
        source="ecSiteType"
        label={translate('resources.registers.fields.ecSiteType')}
        validate={required()}
        choices={EC_SITE_TYPE}
      />
      <TextInput source="ecSiteUrl" label={translate('resources.registers.fields.ecSiteUrl')} validate={required()} />
      <ImageInput
        source="storeLogoThumbnail"
        label="ストア画像"
        accept="image/png"
        defaultValue={defaultValues.storeLogoThumbnail}
        helperText={
          <span
            dangerouslySetInnerHTML={{
              __html: 'ストア画像はPNG形式でアップロードしてください。<br/>幅360px、高さ300px以下にリサイズされます。'
            }}
          />
        }
        onChange={(e) => {
          const file = e.target?.files ? e.target.files[0] : e;
          const format = file.type === 'image/png' ? 'PNG' : 'JPEG';
          saveResizedImageToSessionStorage(file, 'storeLogoThumbnail', 180, 150, format, 100);
        }}
        sx={{
          '& .RaFileInput-dropZone': {
            border: `2px solid rgba(0, 0, 0, 0.23)`
          },
          '& .RaImageInput-helperText': {
            whiteSpace: 'normal'
          }
        }}
      >
        <ImageField source="src" title="ストア画像" />
      </ImageInput>

      <ImageInput
        source="bannerImage"
        label="バナー画像"
        accept="image/png,image/jpeg"
        defaultValue={defaultValues.bannerImage}
        helperText={
          <span
            dangerouslySetInnerHTML={{
              __html:
                'バナー画像はJPEG/PNG形式でアップロードしてください。<br/>幅500px、高さ500px以下にリサイズされます。'
            }}
          />
        }
        onChange={(e) => {
          const file = e.target?.files ? e.target.files[0] : e;
          const format = file.type === 'image/png' ? 'PNG' : 'JPEG';
          saveResizedImageToSessionStorage(file, 'bannerImage', 500, 500, format, 100);
        }}
        sx={{
          '& .RaFileInput-dropZone': {
            border: `2px solid rgba(0, 0, 0, 0.23)`
          },
          '& .RaImageInput-helperText': {
            whiteSpace: 'normal'
          }
        }}
      >
        <ImageField source="src" title="バナー画像" />
      </ImageInput> */}
    </Box>
  );
};

const Form: React.FC = () => {
  const navigate = useNavigate();
  const handleError = useErrorHandler();
  const translate = useTranslate();
  const [defaultValues, setDefaultValues] = useState<RegisterFormData | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isRedirectFromConfirm = searchParams.get('redirect-from-confirm') === 'true';

  useEffect(() => {
    const registerToken = sessionStorage.getItem('register_token'); // セッションストレージからトークンを取得
    if (!registerToken) {
      // トークンが存在しない場合は token-error ページにリダイレクト
      navigate('/register/client/token-error', { replace: true });
    }
  }, [navigate]);

  useEffect(() => {
    // formDataとsessionStorageから画像データを取得してdefaultValuesに設定
    const formData = getRegisterFormData() as RegisterFormData;
    // const storeLogoThumbnail = sessionStorage.getItem('storeLogoThumbnail');
    // const bannerImage = sessionStorage.getItem('bannerImage');

    setDefaultValues({
      ...formData,
      ecSiteType: 1,
      // storeLogoThumbnail: storeLogoThumbnail ? { src: storeLogoThumbnail } : undefined,
      // bannerImage: bannerImage ? { src: bannerImage } : undefined
    });
  }, [navigate]);

  const handleSubmit = async (data: RegisterFormData) => {
    setIsLoading(true);
    try {
      const token = getRegisterToken();
      if (!token) {
        navigate('/register/client/token-error');
        return;
      }

      if (!data.address2) {
        data.address2 = '';
      }

      // sessionStorageから画像データを取得して送信データに含める
      // const storeLogoThumbnail = sessionStorage.getItem('storeLogoThumbnail');
      // const bannerImage = sessionStorage.getItem('bannerImage');

      const sendingFormData = {
        token,
        ...data,
        // storeLogoThumbnail,
        // bannerImage,
        // closingDate: 99,
        // invoiceDate: 99,
        // plannedPaymentMonth: 1,
        // plannedPaymentDate: 99
      };

      const registerClientUrl = `/registers/clients/create-test-basic`;
      await dataProvider.register(sendingFormData, registerClientUrl);

      setRegisterFormData(sendingFormData); // sessionStorageに保存
      // navigate(`/register/client/confirm`); // 確認画面へ遷移
      navigate(isRedirectFromConfirm ? `/register/client/confirm` : `/register/client/form-store`);
    } catch (error) {
      handleError();
      return (error as any).body.errors;
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RegisterFormLayout>
      <Container
        sx={{ 
          maxWidth: { sm: '552px', md: '772px' },
          marginTop: { xs: '20px', sm: '40px'},
          marginBottom: { xs: '20px', sm: '40px'},
          borderRadius: { sm: '24px', md: '24px' }, 
          border: { sm: '1px solid #EEEEEE', md: '1px solid #EEEEEE' },
          backgroundColor: '#FFFFFF'
        }}
      >
        <Helmet>
          <title>{translate('resources.registers.name.FormBasicInput')}</title>
        </Helmet>
        <Stack 
          spacing={1}
          sx={{ 
            paddingLeft: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingRight: { xs: '16px', sm: '40px', md: '64px' }, 
            paddingTop: { sm: '40px', md: '64px' },
            paddingBottom: { sm: '40px', md: '64px' }
          }}
        >
          <RegisterHeaderProgress
            title={translate('resources.registers.name.FormBasicInput')}
            icon={<StoreOutlinedIcon />}
            step={3}
            totalSteps={7}
          />

          <Box display="flex" justifyContent="center">
            <Box 
              sx={{
                width: { xs: '288px', sm: '400px' },
                height: '20px',
              }}
              component="img" 
              src={`/images/stepper/img_stepper_3.svg`} 
              alt="stepper_3" 
            />
          </Box>

          <Typography 
            variant="body1"
            sx={{
              marginBottom: '14px'
            }}
          >
            ECサイトを運営している事業者の所在地や連絡先を入力してください。
            <br />
            メールアドレスは本人確認に使用されます。利用可能なことをご確認の上入力してください。
          </Typography>

          <Box display="flex" justifyContent="center">
            <Box
              sx={{
                width: '100%',
                // maxWidth: '600px'
              }}
            >
              <SimpleForm
                defaultValues={defaultValues}
                toolbar={<CustomToolbar loading={isLoading} isRedirectFromConfirm={isRedirectFromConfirm} />}
                onSubmit={handleSubmit}
                component={CustomCardContent}
                gap={3}
                sx={{
                  p: 0,
                  '& .MuiTextField-root': { width: '100%' }
                }}
              >
                {defaultValues && <FormContents defaultValues={defaultValues} />}
              </SimpleForm>
            </Box>
          </Box>
        </Stack>
      </Container>
    </RegisterFormLayout>
  );
};

export default Form;
